/* eslint-disable import/prefer-default-export */
import React from 'react';

// import Placeholder from '../Placeholder';
import Loader from '../Placeholder/loader';

export const SubtitlePlaceholder = () => (
  <Loader
    style={{ minHeight: `42px`, maxWidth: `378px`, marginBottom: `60px` }}
  />
);
