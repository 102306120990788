import React, { useContext } from 'react';
import isStrongPassword from 'validator/lib/isStrongPassword';

import passwordFields from './data';
import { FirebaseContext } from '../Firebase';

import Form from '../Form';
import { passwordsDontMatch, passwordStrength, successMessage } from './errors';

import { loginForm } from '../Account/style';

export default function ChangePasswordForm({
  displayError,
  displaySuccess,
  authUser,
}) {
  const firebase = useContext(FirebaseContext);

  const handleSubmit = ({ data }) => {
    const { current_password, password, password_confirm } = data;

    const { emailAuthProvider, reAuthenticateUser, doPasswordUpdate, auth } =
      firebase;

    if (password !== password_confirm || password === '') {
      displayError(passwordsDontMatch);
    } else {
      const checkPassword = isStrongPassword(password);

      if (!checkPassword) {
        displayError(passwordStrength);
      } else {
        const user = auth.currentUser;
        const credential = emailAuthProvider.credential(
          authUser.email,
          current_password,
        );
        reAuthenticateUser(user, credential).then(() => {
          doPasswordUpdate(user, password)
            .then(() => {
              displaySuccess(successMessage);
            })
            .catch(error => {
              displayError(error.message);
            });
        });
      }
    }
  };
  return (
    <Form
      name="password-change"
      fields={passwordFields}
      action={handleSubmit}
      style={loginForm}
      // debug
    />
  );
}
