import React from 'react';
import parse from 'html-react-parser';

import FormProvider from '../Form/context';
import { checkSize } from '../Responsive/context';
import PasswordChange from '../PasswordChange';

import './dashboard.css';
import alertStyle from '../Alert/style';

import { SubtitlePlaceholder } from '../Subpage/placeholders';
import Spinner from '../Placeholder/spinner';

import loadComponents from '../Loadable';

const Title = loadComponents('h3');
const Alert = loadComponents('alert');
const AccountContact = loadComponents('account-contact');
const AccountDelete = loadComponents('account-delete');

class AccountDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
    };
    this.displaySuccess = this.displaySuccess.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  displaySuccess = message => {
    this.setState({ error: false, success: message });
  };

  displayError = message => {
    this.setState({ error: message, success: false });
  };

  render() {
    const { error, success } = this.state;
    const { authUser, firebase, windowXL } = this.props;

    const sharedProps = {
      authUser,
      firebase,
      displaySuccess: this.displaySuccess,
      displayError: this.displayError,
      fallback: <Spinner size="36px" />,
    };
    return (
      <>
        {error && (
          <Alert style={alertStyle} error>
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={alertStyle} success>
            {success}
          </Alert>
        )}
        <section className="accountDashboard">
          {authUser.providerData[0].providerId === 'password' && (
            <DashboardItem
              title="Change <span>Password</span>"
              style={{ gridArea: `a` }}
            >
              <PasswordChange {...sharedProps} />
            </DashboardItem>
          )}
          <DashboardItem
            title="Saved <span>Address</span>"
            style={{ gridArea: `b` }}
          >
            <FormProvider>
              <AccountContact {...sharedProps} />
            </FormProvider>
          </DashboardItem>
          {windowXL && (
            <DashboardItem
              title="Delete <span>Account</span>"
              style={{ gridArea: `c` }}
            >
              <AccountDelete {...sharedProps} />
            </DashboardItem>
          )}
        </section>
      </>
    );
  }
}

export default checkSize(AccountDashboard);

const DashboardItem = ({ title, children, style }) => (
  <div className="dashboardSection" style={style}>
    <Title
      style={{ textTransform: `uppercase` }}
      fallback={<SubtitlePlaceholder />}
    >
      {parse(title)}
    </Title>
    {children}
  </div>
);
