export default [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'current_password',
        label: 'Current Password',
        required: true,
        hideHint: true,
      },
      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'password',
        label: 'New Password',
        required: true,
        hideHint: true,
        reveal: true,
      },
      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'password_confirm',
        label: 'Confirm New Password',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Change Password',
      },
    ],
  },
];
